export default `
<p>Effective date: August 26, 2022</p>
<p>UAB “Confindo” (“we”, “us”, “Company”) is the controller of personal data you provide to us. We operate the https://www.confindo.com/ website (“Website”) to provide our users (“you” and “your”) with information about our services (“Services”).</p>
<p>Here we describe how we collect, use, and handle your information when you use our Website. By using the Services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
<p>When processing your data, we adhere to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR”), and other applicable data protection laws.</p>
<p>Please note that this Privacy Policy applies only to the Website. This Website may contain links to other websites not operated or controlled by us. The links from this Website do not imply that we endorse or reviewed such third-party websites.</p>
<h3>I. Information about us (data controller):</h3>
<p>UAB “Confindo”<br>
Org. No.: 305534751<br>
Registered address: Kęstučio g. 27-111, Kaunas, Lithuania<br>
E-mail: info@confindo.com<br>
Tel. +370 (611) 399-93
</p>
<h3>II. Information about data protection officer:</h3>
<p>Data protection officer is not appointed.</p>
<h3>III. What information we collect?</h3>
<p>We collect and use the following information:</p>
<p>1. Personal Data</p>
<p>When you use our Website, we may collect certain personally identifiable information about you (“Personal Data”), i.e., when you voluntarily provide such information such as when you use our Services, contact us with inquiries, etc. Wherever we collect Personal Data we provide a link to this Privacy Policy.</p>
<p>2. Usage Data</p>
<p>We may also collect information that your browser sends whenever you visit our Website or when you access the Website by or through a mobile device ("Usage Data").</p>
<p>This Usage Data may include information such as IP addresses, the type of browser and device you use, the web page you visited before coming to our sites, and identifiers associated with your devices.</p>
<p>When you access the Website by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
<p>Please note that such information, which is collected passively using various technologies, cannot presently be used to specifically identify you. This Website may use such information and pool it with other information to track, for example, the total number of visitors to our Website, the number of visitors to each page of our Website, the domain names of our visitors' Internet service providers, and how our users use and interact with the Services.</p>
<p>3. Tracking Cookies Data</p>
<p>We do not use cookies or similar tracking technologies to track the activity on our Website.</p>
<h3>IV. Collected and processed Personal Data, the purposes, and legal grounds for processing of Personal Data, as well as the terms of Personal Data storage.</h3>
<div class="table-wrapper"><table><thead>
<th>Purposes of data processing</th>
<th>Collected and processed data</th>
<th>Legal grounds for processing</th>
<th>Storage term</th>
</thead><tbody><tr>
<td>For purposes of communication through the Website, for example to respond to the request made by the data subject</td>
<td><ul><li>First name</li>
<li>Last name</li>
<li>Email address</li>
<li>Phone number</li></ul>
</td>
<td>Processing is for the purposes of the legitimate interests pursued by the Company (GDPR Article 6(1)(f)).</td>
<td>3 (three) years from the date of the last correspondence with the data subject</td>
</tr></tbody></table></div>
<p>We use Personal Data for various purposes stated in this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem. Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. We  and our subsidiaries and affiliates (“Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Website to help us improve the content and functionality of the Website, to better understand our users and to improve the Services. We and our Related Companies may use this information to communicate with you regarding the Services.</p>
<h3>V. Are you required to provide us Personal Data?</h3>
<p>Please note that in order for you to use our Services, you must provide us with the abovementioned Personal Data.</p>
<h3>VI. Where do we get your Personal Data from (where does the data come from)?</h3>
<p>We receive data directly from you, i.e., when you provide your Personal Data through our Website.</p>
<h3>VII. Transfer of Data</h3>
<p>The information you provide to us, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy.</p>
<p>Please note that we will not share your Personal Data with others, without your permission, except for the following purposes:</p>
<p>1. Others working for us (service providers)</p>
<p>We can use certain trusted third parties (for example, providers of customer support and IT services) to help us improve our Website. These third parties may access information only to perform tasks on our behalf in compliance with this Privacy Policy, and we'll remain responsible for their handling of your information per our instructions.</p>
<p>2. Related Companies</p>
<p>We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.</p>
<p>3. Business transfers</p>
<p>As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</p>
<p>4. Law & Order</p>
<p>We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to (a) comply with the law; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of us or our users; or (d) protect our property rights.</p>
<p>We do not transfer your Personal Data to third country or international organisation, i.e., outside the European Union, unless required to do so by law or by the courts or as required for one of the purposes set out in this Policy.</p>
<h3>VIII. Data Subjects’ Rights</h3>
<p>Regarding the submission of your Personal Data via our Website and according to the GDPR, you have the following rights:</p>
<ul>
<li>the right to be informed about data processing;</li>
<li>the right of access;</li>
<li>the right to rectification;</li>
<li>the right to erasure (‘right to be forgotten’);</li>
<li>the right to data portability;</li>
<li>the right to restriction of processing;</li>
<li>the right to object.</li>
</ul>
<p>Please be advised that you have the right to lodge a complaint with a data protection authority.</p>
<p>You also have the right to withdraw your consent (if you have given one) for the processing of your Personal Data at any time. You can withdraw your consent at any time by writing to us at info@confindo.com. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.</p>
<p>In order to exercise abovementioned rights, you must submit a request in writing and in a way that identifies you, i.e., affirming your identity. We do not process requests without the ability to identify the applicant.</p>
<h3>IX. Changes</h3>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. This Privacy Policy was last updated on the date indicated above. Your continued use of the Website after any changes or revisions to this Privacy Policy shall indicate your agreement with the terms of such revised Privacy Policy.</p>`;
